import {DirectedGraph} from "@joint/layout-directed-graph";
import {AvoidLib} from "libavoid-js";
import {AvoidRouter} from "./LibAvoidRouter";
import {dia} from "@joint/core";
import {MutableRefObject, useCallback, useEffect, useRef} from "react";
import Graph = dia.Graph;
import pRetry from "p-retry";

export const useLayout = (graphRef: MutableRefObject<Graph | null>) => {
    const avoidRouterRef = useRef<AvoidRouter | null>(null);

    useEffect(() => {
        new Promise<void>((resolve, reject) => {
            if (AvoidLib.avoidLib === null) {
                console.log("loading avoidlib")
                AvoidRouter.load().then(() => {
                    console.log("resolved avoidlib")
                    resolve()
                }).catch(error => reject(error))
            } else {
                resolve();
            }
        }).then(_ => {
            // because on slow CPU it might not be loaded in time
            pRetry(() => {
                avoidRouterRef.current = new AvoidRouter(graphRef.current, {
                    shapeBufferDistance: 20,
                    idealNudgingDistance: 20,
                    portOverflow: 10,
                });
                avoidRouterRef.current.addGraphListeners();
                avoidRouterRef.current.routeAll();
            }, {
                retries: 10,
                minTimeout: 500,
                onFailedAttempt: (error) => {
                    console.log(`Attempted to load avoidlib-js but got ${error}, retrying ...`);
                },
            })
        }).catch(error => console.error(error))

        return () => {
            avoidRouterRef.current = null;
        }
    }, [graphRef]);

    const updateLayout = useCallback(() => {
        if (graphRef.current && graphRef.current.getCells().length) {
            DirectedGraph.layout(graphRef.current, {
                marginX: 80,
                marginY: 40,
                rankDir: 'TB',
                edgeSep: 80,
                rankSep: 60,
                ranker: 'tight-tree'
            });
        }
    }, [graphRef])

    useEffect(() => {
        updateLayout();
    }, [updateLayout]);

    return {
        updateLayout
    }
}