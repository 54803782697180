import {dia, shapes} from "@joint/core";
import {useCallback, useEffect, useRef, useState} from "react";
import {useRecoilState} from "recoil";
import {processState} from "../../../store";
import {mapToGraphElement, mapToGraphLinks} from "./MapperUtil";
import debounce from 'lodash-es/debounce';
import Cell = dia.Cell;


export interface GraphEventHandlers {
    onGraphChange: (needsLayouting: boolean) => void;
}

export const useGraph = (eventHandlers: GraphEventHandlers) => {
    const graphRef = useRef<dia.Graph | null>(null);
    const [process, setProcess] = useRecoilState(processState)
    const [initialLoad, setInitialLoad] = useState(true)

    const handleGraphRemove = useCallback((cell: Cell) => {
        if (cell.isLink() && (cell.target().id && cell.source().id)) {
            setProcess(prev => ({
                ...prev,
                process: {
                    ...prev.process,
                    links: prev.process.links.filter(link => !(link.sourceTaskUuid === cell.source().id
                        && link.targetTaskUuid === cell.target().id))
                }
            }))
        } else if (cell.isElement()) {
            setProcess(prev => ({
                ...prev,
                process: {
                    ...prev.process,
                    links: prev.process.links.filter(link =>
                        link.sourceTaskUuid === cell.id
                        || link.targetTaskUuid === cell.id),
                    tasks: prev.process.tasks.filter(task => task.uuid !== cell.id)
                }
            }))
        }
    }, [setProcess])

    const handleGraphChange = useCallback((cell: Cell) => {
        if (cell.isLink() && cell.get('target').id) {
            setProcess(prev => {
                return prev.process.links.some(link => link.sourceTaskUuid === cell.source().id && link.targetTaskUuid === cell.target().id) ? prev : ({
                    ...prev,
                    process: {
                        ...prev.process,
                        links: [...prev.process.links, {
                            sourceTaskUuid: cell.source()!.id as string,
                            targetTaskUuid: cell.target()!.id as string,
                        }]
                    }
                })
            })
        } else if (cell.isElement()) {
            updateElementPositionDebounced(cell)
        }
    }, [setProcess])

    const updateElementPosition = useCallback((cell: Cell) => {
        setProcess(prev => ({
            ...prev,
            process: {
                ...prev.process,
                graphMetadata: {
                    ...prev.process.graphMetadata,
                    [cell.id]: {position: {x: cell.position().x, y: cell.position().y}},
                    layoutSet: prev.process.graphMetadata.layoutSet
                }
            }
        }))
    }, [setProcess])

    const updateElementPositionDebounced = debounce(updateElementPosition, 25, {
        trailing: true,
        maxWait: 25,
        leading: true
    })

    useEffect(() => {
        graphRef.current = new dia.Graph({}, {
            cellNamespace: {
                ...shapes
            }
        });
        graphRef.current!.on('add', handleGraphChange)
        graphRef.current!.on('remove', handleGraphRemove)
        graphRef.current!.on('change', handleGraphChange)
        setProcess(prev => ({...prev}))

        return () => {
            graphRef.current!.off('add', handleGraphChange)
            graphRef.current!.off('remove', handleGraphRemove)
            graphRef.current!.off('change', handleGraphChange)
        }
    }, [handleGraphChange, handleGraphRemove, setProcess]);

    /**
     * Update graph from process
     */
    useEffect(() => {
        const optionalMap = new Map(process.process.tasks.map(task => [task.uuid, task.optional]))
        const links = process.process.links.map(link => mapToGraphLinks(link, optionalMap.get(link.targetTaskUuid)!));
        const tasks = process.process.tasks.map(task => mapToGraphElement(task, process.process.graphMetadata));

        graphRef.current?.startBatch('update')
        try {
            if (initialLoad && tasks.length) {
                graphRef.current?.addCells([...tasks, ...links]);
                setInitialLoad(false)
            } else {
                tasks.forEach(task => {
                    const element = graphRef.current?.getCell(task.id)
                    if (!element) {
                        graphRef.current?.addCell(task)
                    } else {
                        element.attr('title', task.attr('title'))
                        element.attr('team', task.attr('team'))
                        element.attr('tool', task.attr('tool'))
                        element.attr('optional', task.attr('optional'))
                        element.attr('body', task.attr('body'))
                    }
                })


                const linkMap = new Map(graphRef.current?.getLinks()
                    .map(link => [link.source().id + "_" + link.target().id, link]))
                links.forEach(link => {
                    const edge = linkMap.get(link.source().id + "_" + link.target().id)
                    edge?.attr('line', link.attr('line'))
                })
            }


            let layoutSet = false;
            if (process.process.tasks.length > 0) {
                layoutSet = process.process.graphMetadata['layoutSet']
                setProcess(prev => ({
                    ...prev, process: {
                        ...prev.process,
                        graphMetadata: {
                            ...prev.process.graphMetadata,
                            layoutSet: true
                        }
                    }
                }))
            }
            eventHandlers.onGraphChange(!layoutSet)
        } finally {
            graphRef.current?.stopBatch('update')
        }
    }, [process.process.tasks, process.process.links]);

    return {
        graphRef,
    }
}




