import React, {useCallback, useEffect, useRef} from "react";
import {useGraph} from "./UseGraph";
import {ArrowsPointingInIcon,} from "@heroicons/react/24/solid";
import {useSvgPanZoom} from "./UseSvgPanZoom";
import {usePaper} from "./UsePaper";
import {useLayout} from "./UseLayout";
import {ArrowPathIcon} from "@heroicons/react/16/solid";
import {ArrowPathRoundedSquareIcon} from "@heroicons/react/24/outline";

interface ProcessGraphProps {
    editMode?: boolean,
    hide: boolean,
    registerTriggerPdfExport: (triggerPdfExport: () => void) => void
}

export function ProcessGraph({editMode, hide, registerTriggerPdfExport}: ProcessGraphProps) {
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const {graphRef} = useGraph({
        onGraphChange: requiresLayoutUpdate => requiresLayoutUpdate && updateLayout()
    })
    const {paperRef} = usePaper(canvasRef, graphRef, !!editMode);
    const {updateLayout} = useLayout(graphRef);
    const {fitOnScreen} = useSvgPanZoom(paperRef, canvasRef);

    const handleExportGraph = useCallback(() => {
        fitOnScreen();

        setTimeout(() => {
            const svgElement = canvasRef.current?.querySelector('svg');

            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const blob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'process.svg';
                link.click();
                URL.revokeObjectURL(url);
            }
        }, 500)
    }, [fitOnScreen]);

    useEffect(() => {
        registerTriggerPdfExport(() => handleExportGraph)
    }, [registerTriggerPdfExport, handleExportGraph]);

    return (
        <div style={{position: "sticky", top: 20}}>
            {!hide && <div className="tool-container d-flex flex-row">
                <div className="m-1">
                    <ArrowsPointingInIcon onClick={() => fitOnScreen()} className={"icon-style"}/>
                </div>
                <div className="m-1 ms-0">
                    <ArrowPathRoundedSquareIcon onClick={() => {
                        updateLayout()
                        requestAnimationFrame(() => {
                            fitOnScreen()
                        })
                    }} className={"icon-style"}/>
                </div>
            </div>}
            <div style={{
                opacity: 1,
                height: "75vh",
                width: "100%",
                zIndex: 1000,
            }} className="canvas" ref={canvasRef}/>
        </div>
    );
}