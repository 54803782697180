import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router";
import {useRecoilState} from "recoil";
import {organizationUuidState, userState} from "../store";
import {getUser} from "../api";

interface SecuredProps {
    children: any;
}

export default function NavigationGuard(props: SecuredProps) {
    const [user, setUser] = useRecoilState(userState)
    const [organizationUuid, setOrganizationUuid] = useRecoilState(organizationUuidState)
    const navigate = useNavigate();
    const location = useLocation();
    const permitView = user?.email;

    useEffect(() => {
        getUser().then(data => {
            setUser(data)
            if(!organizationUuid && data.organizations?.length) {
                setOrganizationUuid(data.organizations[0].uuid)
            }
        }).catch(_ => {
            setUser(null);
            navigate("/landing")
        })
    }, [location.pathname, navigate, setUser, organizationUuid, setOrganizationUuid]);

    return (permitView && props.children)
}